import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettingsService } from './app-settings.service';
import { ActionType, EntityType, SignalRNotification } from '@core/models/notification-hub';
import { DexieDbProvider } from '@shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private _edaraCoreApiBaseUrl: string;
  private hubConnection?: signalR.HubConnection;

  // Observable to broadcast messages to components
  private notificationSubject = new BehaviorSubject<SignalRNotification | null>(null);
  currentNotification$: Observable<SignalRNotification | null> = this.notificationSubject.asObservable();

  private readonly entityApiEndpoints: Record<EntityType, string> = {
    [EntityType.PurchaseOrder]: '/api/PurchaseOrders/'
  };

  private readonly dexieDbTableMapping: Record<EntityType, string> = {
    [EntityType.PurchaseOrder]: 'PRCH_PurchaseOrders',
  };

  constructor(private httpClient: HttpClient, private localDbProvider: DexieDbProvider) {
    this._edaraCoreApiBaseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
    if (this._edaraCoreApiBaseUrl.endsWith('/')) {
      this._edaraCoreApiBaseUrl = this._edaraCoreApiBaseUrl.slice(0, -1);
    }
  }

  // Connect to SignalR hub
  public startConnection(tenantId: string): void {
    const url = `${this._edaraCoreApiBaseUrl}/notificationshub`;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        withCredentials: false
      })
      .withAutomaticReconnect()
      .build();

    this.hubConnection
      .start()
      .then(() => {
        console.log('Connection started.');
        this.joinGroup(tenantId);
      })
      .catch(err => console.error('Error while starting connection:', err));

    this.hubConnection.on('ReceiveNotification', (data: SignalRNotification) => {
      this.processIncomingNotification(data);
    });
  }

  // Method to join a group by tenant ID
  public joinGroup(tenantId: string): void {
    this.hubConnection?.invoke('JoinGroup', tenantId)
      .then(() => console.log(`Joined group: ${tenantId}`))
      .catch(err => console.error('Error while joining group:', err));
  }

  // Method to leave a group by tenant ID
  public leaveGroup(tenantId: string): void {
    this.hubConnection?.invoke('LeaveGroup', tenantId)
      .then(() => console.log(`Left group: ${tenantId}`))
      .catch(err => console.error('Error while leaving group:', err));
  }

  private processIncomingNotification(notification: SignalRNotification) {
    switch (notification.actionType) {
      case ActionType.Create:
      case ActionType.Update:
        this.handleCreateOrUpdate(notification);
        break;
      case ActionType.Delete:
        this.handleDelete(notification);
        break;
      default:
        console.warn(`Unhandled action type: ${notification.actionType}`);
    }
  }

  private handleCreateOrUpdate(notification: SignalRNotification) {
    // const tableName = this.dexieDbTableMapping[notification.entityType];
    // if (!tableName) {
    //   console.warn(`No local DB table found for entity type: ${notification.entityType}`);
    //   return;
    // }

    const url = `${this._edaraCoreApiBaseUrl}${this.entityApiEndpoints[notification.entityType]}${notification.entityId}`;
    this.httpClient.get(url).subscribe({
      next: (data) => {
        // this.localDbProvider.db[tableName].put(data)
        //   .then(() => this.notificationSubject.next(notification))
        //   .catch((err: any) => this.handleLocalDatabaseError(err, 'updating'));
      },
      error: (error) => {
        console.error('Error fetching entity:', error);
      }
    });
  }

  private handleDelete(notification: SignalRNotification) {
    // const tableName = this.dexieDbTableMapping[notification.entityType];
    // if (!tableName) {
    //   console.warn(`No local DB table found for entity type: ${notification.entityType}`);
    //   return;
    // }

    // this.localDbProvider.db[tableName].delete(notification.entityId)
    //   .then(() => this.notificationSubject.next(notification))
    //   .catch((err: any) => this.handleLocalDatabaseError(err, 'deleting'));
  }

  private handleLocalDatabaseError(error: any, operation: string) {
    console.error(`Error ${operation} entity in IndexedDB:`, error);
    this.localDbProvider.handleErrors(error);
  }

}