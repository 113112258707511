export interface SignalRNotification {
    entityId: string;
    entityType: EntityType;
    actionType: ActionType;
    extraAttributes: any;
}

export enum EntityType {
    PurchaseOrder = 0
}

export enum ActionType {
    Create = 0,
    Update = 1,
    Delete = 2
}